<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
html {
  background-color: #efefef;
}

body {
  margin: 0 auto;
}
</style>
