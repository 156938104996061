import { createRouter, createWebHistory } from 'vue-router';
import Beboerliste from './BeboerlistePage.vue';
import Admin from './AdminPage.vue';

const routes = [
  {
    path: '/',
    name: 'Beboerliste',
    component: Beboerliste,
  },
  {
    path: '/admin',
    name: 'Admin',
    component: Admin,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
